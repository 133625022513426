const en = {
  createTrip: "Create New Trip",
  warehouse: "Warehouse",
  driver: "Driver",
  vehicle: "Vehicle",
  pickupShipments: "Pickup Shipments",
  deliveryShipment: "Delivery Shipment",
  pickupType: "Pickup Type",
  shipper: "Shipper",
  shipmentType: "Shipment Type",
  location: "Location",
  awb: "AWB",
  deliveryType: "Delivery Type",
  attempts: "Attempts",
  fromWarehouse: "From Warehouse",
  creationTime: "Creation Time",
  dispatchTime: "Dispatch Time",
  lastUpdate: "Last Update",
  pickups: "Pickups",
  deliveries: "Deliveries",
  collectedCod: "Collected COD",
  action: "Action",
  activeTrips: "Active Trips",
  pendingTrips: "Pending Trips",
  completedTrips: "Completed Trips",
  checkInTime: "Check-In Time",
  pendingShipments: "Pending Shipments",
  creationDate: "Creation Date",
  export: "Export",
  inTripShipments: "InTrip Shipments",
  bagNo: "Bag No.",
  bagNote: "Bag Note",
  origin: "Origin",
  transporter: "Transporter",
  locationType: "Location Type",
  shelfShipments: "Delivered Shipments",
  checkOutStatus: "Check Out Status",
  shelfTime: "Shelf Time",
  deliveryLocation: "Delivery Location",
  arrivedFrom: "Arrived From",
  deliveryWarehouse: "Delivery Warehouse",
  // interCityShipments: "Inter City Shipments",
  bulkCargoShipments: "Scheduled Shipments",
  transportedShipments: "Transit Shipments",
  from: "From",
  to: "To",
  transportedTrip: "Transported Trip",
  cancelledShipments: "Cancelled Shipments",
  returnType: "Return Type",
  cancellationReason: "Cancellation Reason",
  undefineShipments: "Undefine Shipments",
  customerName: "Customer Name",
  contactNumber: "Contact Number",
  reason: "Reason",
  shipmentReport: "Shipment Report",
  startDate: "Start Date",
  endDate: "End Date",
  optional: "Optional",
  searchShipments: "Search Shipments",
  searchByAwb: "Search By AWB",
  info: "Info",
  searchByCustomerId: "Search By Customer ID",
  searchByContactNumber: "Search by Customer Number",
  search: "Search",
  currentLocation: "Current Location",
  update: "Update",
  cancel: "Cancel",
  updateReceiverInformation: "Update Receeiver Information",
  receiverName: "Receiver Name",
  enterOtp: "Enter OTP",
  sendOtp: "Send OTP",
  sender: "Sender",
  updateDeliveryLocation: "Update Delivery Location",
  address: "Address",
  optionalForLastMile: "Optional For Last Mile",
  customer: "Customer",
  shipperContact: "Shipper Contact",
  shipperType: "Shipper Type",
  weight: "Weight",
  deliveryMode: "Delivery Mode",
  shipmentDetail: "Shipment Detail",
  note: "Note ",
  cod: "COD",
  prepaid: "Prepaid",
  customerCancellation: "Customer Cancellation",
  cancellationNote: "Cancellation Note",
  ziyadCancellation: "Ziyad Cancellation",
  ifZiyad: "If Ziyad Employee",
  shipmentLogs: "Shipment Logs",
  markShipments: "Mark Shipments",
  lastAction: "Last Action",
  transit: "Transit",
  viewWarehouses: "View Warehouses",
  codCollection: "COD Collection",
  id: "ID",
  city: "City",
  addWarehouse: "Add Warehouse",
  floorShipments: "Report Shipments",
  country: "Country",
  displayAddress: "Display Address",
  addLocation: "Add Location",
  codPrepaid: "COD / Prepaid",
  landlineNumber: "Landline Number",
  postalCode: "Postal Code",
  discard: "Discard",
  editWarehouse: "Edit Warehouse",
  assetsInventory: "Assets Inventory",
  addAssets: "Add Assets",
  serialCode: "Serial Code",
  status: "Status",
  allocatedTo: "Allocated To",
  title: "Title",
  attachment: "Attachment",
  attachmentProof: "Attachment Proof",
  serialNumber: "Serial Number",
  asset: "Asset",
  create: "Create",
  editAsset: "Edit Asset",
  returnShipments: "Return Shipments",
  returnOfAsset: "Return of Asset",
  returnLocation: "Return Location",
  warehouseCrew: "Warehouse Crew",
  listedDate: "Listed Date",
  assetLog: "Asset Log",
  warehouseShelves: "Warehouse Shelves",
  shelfId: "Shelf ID",
  addShelves: "Add Shelves",
  addShipper: "Add Shipper",
  shelves: "Shelves",
  warehouseTerminal: "Warehouse Terminal",
  userId: "User ID",
  name: "Name",
  exitTerminal: "Exit Terminal",
  terminalTask: "Terminal Task",
  completeDriverTrip: "Complete Driver Trip",
  driverType: "Driver Type",
  activateDriverTrip: "Activate Driver Trip",
  activateTrip: "Activate Trip",
  receiveTransportedBagDomestic: "Receive Transported Bag Domestic",
  receiveInternationalConsignment: "Receive International Consignment",
  createTransportedBagDomestic: "Create Transported Bag Domestic",
  createInternationalConsignment: "Create International Consignment",
  tripNo: "Trip No.",
  scan: "Scan",
  or: "or",
  enter: "Enter",
  transported: "Transported",
  dockPort: "Dock Port",
  scannedShipments: "Scanned Shipments",
  pendingToScan: "Pending To Scan",
  awbToScan: "AWB to Scan",
  return: "Return",
  pickedUp: "Picked Up",
  checkInStatus: "Check In Status",
  collectedCash: "Collected Cash",
  closeTrip: "Close Trip",
  show: "Show",
  missingShipmentNote: "Missing Shipment Note",
  received: "Received",
  fromCity: "From City",
  fromCountry: "From Country",
  fromPort: "From Port",
  transportBag: "Transport Bag",
  showShipments: "Show Shipments",
  selectDeliveryLocation: "Select Delivery Location",
  selectTransporter: "Select Transporter",
  viewWarehouseStaff: "View Warehouse Staff",
  addStaff: "Add Staff",
  designation: "Designation",
  addNew: "Add New",
  destination: "Destination",
  firstName: "First Name",
  lastName: "Last Name",
  streetAddress: "Street Address",
  selectDesignation: "Select Designation",
  selectWarehouse: "Select Warehouse",
  iqama: "Iqama",
  passportNumber: "Passport Number",
  passportAttachment: "Passport Attachment",
  contractId: "Contract ID",
  contract: "Contract",
  salaryInSar: "Salary In SAR",
  contractTenureInMonths: "Contract Tenure In Months",
  employmentStatus: "Employment Status",
  employeeId: "Employee ID",
  employer: "Employer",
  ifVendor: "If Vendor",
  editStaffMember: "Edit Staff Member",
  viewStaff: "View Staff",
  viewShipper: "View Shipper",
  contractExpiry: "Contract Expiry",
  idProof: "ID Proof",
  salary: "Salary",
  fullName: "Full Name",
  monogram: "Monogram",
  logo: "Logo",
  owenerIqama: "Owner's iqama",
  tenure: "Tenure",
  crNumber: "CR Number",
  crAttachment: "CR Attachment",
  vatNumber: "VAT Number",
  vatAttachment: "Vat Attachment",
  office: "Office",
  ownerPostalCode: "Owner's Postal Code",
  officePostalCode: "Office Postal Code",
  businessName: "Business Name",
  iqamaAttachment: "Iqama Attachment",
  contractTenure: "Contract Tenure",
  contractAttachment: "Contract Attachment",
  joiningDate: "Joining Date",
  employeeType: "Employee Type",
  vendor: "Vendor",
  contractEmp: "Contract EMP",
  staffManagement: "Staff Management",
  selectEmployee: "Select Employee",
  generateCredentials: "Generate Credentials",
  terminateUser: "Terminate User",
  generate: "Generate",
  password: "Password",
  terminationReason: "Termination Reason",
  viewDrivers: "View Drivers",
  addDriver: "Add Driver",
  employedBy: "Employed By",
  editDriver: "Edit Driver",
  details: "details",
  staffMemberDetail: "Staff Member Detail",
  idNumber: "ID Number",
  driverManagement: "Driver Management",
  selectDriver: "Select Driver",
  addDriverSelfVehicle: "Add Driver Self Vehicle",
  forFreelance: "For Freelance or vendor provided driver",
  vehiclePlateNo: "Vehicle Plate No.",
  vehicleModel: "Vehicle Model",
  vehicleMakeYear: "Vehicle Made Year",
  email: "Email",
  cargoCapacity: "Cargo Capacity",
  addVehicle: "Add Vehicle",
  addDriverVehicle: "Add Driver Vehicle",
  invoices: "Invoices",
  newInovice: "New Invoice",
  invoiceTo: "Invoice To",
  invoiceFor: "Invoice For",
  amount: "Amount ",
  inoviceNo: "Invoice No. ",
  toDate: "To Date",
  shippingCharge: "Shipping Charges",
  insurance: "Insurance",
  onlineCod: "Online COD",
  taxableAmount: "Taxable Amount",
  vat: "VAT",
  total: "Total",
  exportSheet: "Export Sheet",
  invoicesDetail: "Invoices Detail",
  billingType: "Billing Type",
  balance: "Balance",
  credit: "Credit",
  lastBillClosingDate: "Last Bill Closing date",
  newInvoiceClosingDate: "New Invoice Closing Date",
  selectPaymentMethod: "Select Payment Method",
  creditAmount: "Credit Amount",
  paymentProof: "Payment Proof",
  shipperAccounts: "Shipper Accounts",
  transactionDescription: "Transaction Description",
  Credit: "Credit",
  Debit: "Debit",
  netBalance: "Net Balance",
  recordedTime: "Recorded Time",
  proof: "Proof",
  printStatement: "Print Statement",
  accountStatement: "Account Statement",
  financeCod: "Finance COD",
  pendingCod: "Pending COD",
  warehouseCod: "Warehouse COD",
  warehouseCollection: "Warehouse Collection",
  driverCollection: "Driver Collection",
  codReturn: "COD Return",
  newReturn: "New Return",
  paymentType: "Payment Type",
  codReturnNo: "COD Return Type",
  referenceNumber: "Reference Number",
  createNewReturnNote: "Create New Return Note",
  lastCodClosingDate: "Last COD Closing Date",
  newCodClosingDate: "New COD Closing Date",
  newCodReturnDueDate: "New COD Return Due Date",
  makeYear: "Make Year",
  mileage: "Mileage",
  makeModel: "Make Model",
  purchaseSlip: "Purchase Slip",
  registrationSlip: "Registration Slip",
  registration: "Registration",
  viewVehicles: "View Vehicles",
  editVehicle: "Edit Vehicle",
  dashboard: "Dashboard",
  shipments: "Shipments",
  sales: "Sales",
  fleet: "Fleet",
  finance: "Finance",
  scheduling: "Scheduling",
  shipmentViews: "Shipment Views",
  shipmentControls: "Shipment Controls",
  warehouseManagement: "Warehouse Management",
  pendingPickup: "Pending Pickups",
  warehouseInventory: "Warehouse Inventory",
  newInvoice: "New Invoice",
  createInvoice: "Create Invoice",
  newInvoiceDueDate: "New Invoice Due Date",
  forPostpaid: "For Postpaid",
  forPrepaid: "For Prepaid",
  postpaid: "Postpaid",
  shippingCharges: "Shipping Charges",
  invoiceDetails: "Invoice Details",
  fromDate: "From Date",
  invoiceNumber: "Invoice Number",
  shipperBilling: "Shipper Billing",
  codManagement: "COD Management",
  expenseManagement: "Expense Management",
  payouts: "Payouts",
  utilityPayouts: "Utility Payouts",
  otherPayables: "Other Payables",
  exportStatement: "Export Statement",
  financeType: "Finance Type",
  lastClosingDate: "Last Closing Date",
  payableType: "Payable Type",
  forSalary: "For Salary",
  forMonthly: "For Monthly",
  forCompensation: "For Compensation",
  totalAmount: "Total Amount",
  createPaySlip: "Create Pay Slip",
  dueDate: "Due Date",
  selectUser: "Select User",
  description: "Description",
  amountAfterDueDate: "Amount After Due Date",
  utilityPayments: "Utility Payments",
  selectTenure: "Select Tenure",
  selectDate: "Select Date",
  selectExpense: "Select Expense",
  expenseTitle: "Expense Title",
  net: "Net",
  tax: "Tax",
  quantity: "Quantity",
  createExpense: "Create Expense",
  deliveryAttempts: "Delivery Attempts",
  totalOrders: "Total Orders",
  lastMonthOrders: "Last 30 Days Orders",
  openOrders: "Open Orders",
  newOrders: "New Orders",
  totalRevenue: "Total Revenue",
  lastMonthRevenue: "Last 30 Days Revenue",
  collectedBalance: "Collected Balance",
  pending: "Pending",
  shipmentThresholds: "Shipment Thresholds",
  version1: "Version 1",
  shippersDashboard: "Shipper's Dashboard",
  mapLink: "Map Link",
  longitude: "Longitude",
  latitude: "Latitude",
  shipmentTitle: "Shipment Title",
  shipmentValue: "Shipment Value",
  shipmentDetails: "Shipment Details",
  additionalDetails: "Additional Details",
  atShipmentValue: "At Shipment Value",
  generateBillingDraft: "Generate Billing Draft",
  cargoType: "Cargo Type",
  idAttachment: "ID Attachment",
  gender: "Gender",
  dateOfBirth: "Date of Birth",
  shipperToCustomer: "Shipper To Customer",
  customerToShipper: "Customer To Shipper",
  selectShipmentType: "Select Shipment Type",
  itemCode: "Item Code",
  subTotal: "Sub Total",
  grandTotal: "Grand Total",
  businessToBusiness: "Business To Business",
  reallocate: "Reallocate",
  allocation: "Allocation",
  ziyadLogistics: "Ziyad Logistics",
  english: "عربي",
  home: "Home",
  about: "About",
  signin: "Sign In",
  signup: "Sign Up",
  username: "Username",
  addDriverAndVehicle: "Add Driver and Vehicle",
  driverDetails: "Driver Details",
  createShipments: "Create Shipments",
  serviceInquiry: "Service Inquiry",
  enterMessage: "Enter Message",
  cargo: "Cargo",
  forgotPassword: "Forgot Password?",
  signUpWithEmail: "Sign Up With Email",
  signUpWithGoogle: "Sign Up With Google",
  signUpWithFacebook: "Sign Up With Facebook",
  offeringServices: "Offering a Wide Range of",
  logisticsServices: "Logistics Services",
  weOffer: "We Offer...",
  returnShipment: "Return Shipment",
  shipmentInquiry: "Shipment Inquiry",
  confirmPassword: "Confirm Password",
  nidNumber: "NID Number",
  uploadNid: "Upload NID",
  submit: "Submit",
  continueRegistration: "Continue Registration",
  selectVehicle: "Select Vehicle",
  selectCity: "Select City",
  selectCountry: "Select Country",
  selectLocation: "Select Location",
  selectShipper : "Select Shipper",
};
export default en;
